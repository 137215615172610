
import { mapGetters, mapActions } from 'vuex';
// import FsLightbox from "fslightbox-vue";

export default {
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'nonAthletes/record',
      loading: 'nonAthletes/loading',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },

    isDark() {
      return this.$q.dark.isActive;
    },
    // evenNumbers() {
    //     if (this.record) {
    //         return this.record.images.map((_, i) => i).filter(i => i % 3 === 0)
    //     }
    //     return [];
    // },
    options() {
      return {
        rewind: true,
        gap: '1.25rem',
        perPage: 2,
        breakpoints: {
          640: {
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
  },
  data() {
    return {
      // string: `<p><span class="text-huge" style="color:hsl(0, 75%, 60%);font-family:Georgia, serif;">the header</span></p><p>text</p><p>&nbsp;</p><p><span class="text-big" style="background-color:hsl(180, 75%, 60%);"><i><u>the header2</u></i></span></p><p><mark class="marker-yellow">text2</mark></p>`,
      // myArray: [],
    };
  },
  components: {
    // FsLightbox,
  },
  methods: {
    wantedLang(whatEver) {
      return this.isRTL ? whatEver.ar : whatEver.en;
    },
    openImageModal(index) {
      this.toggler = !this.toggler;
      this.slideIndex = index;
    },
    ...mapActions({
      doFind: 'nonAthletes/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      return str.split(/\s+/).slice(1, 2).toString();
    },
    removeFirstTwoWord(description) {
      let str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  async created() {
    await this.doFind(this.$route.params.id);
    // this.myArray = this.record.details;
    // this.myArray.forEach((e) => {
    //   e.subject.en = e.subject.en
    //     .split('<p><span class=')
    //     .map((el) => '<p><span class='.concat(el));
    //   console.log('hhh', e.subject.en);
    //   if (e.subject.en.length > 1) {
    //     e.subject.en.shift();
    //   }
    //   e.subject.ar = e.subject.ar
    //     .split('<p><span class=')
    //     .map((el) => '<p><span class='.concat(el));
    //   if (e.subject.ar.length > 1) {
    //     e.subject.ar.shift();
    //   }
    // });
    // console.log('this is my myArray', this.myArray);
  },
};
